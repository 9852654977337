/**
 * Controller for user authentication applications
 */
(function () {
    'use strict';

    /* eslint-disable-next-line no-undef */
    var app = angular
        .module('brightfunnelUserAuth');

    app.controller('loginCtrl', loginCtrl);

    loginCtrl.$inject = ['$scope', 'userAuthConstants',
        'userAuthSrvc', 'environmentSrvc'];

    function loginCtrl($scope, userAuthConstants,
        userAuthSrvc, environmentSrvc) {

        function submit() {
            $scope.button.enabled = false;
            $scope.error.text = userAuthSrvc.verifyEmail($scope.email.value,
                userAuthConstants);

            if (!$scope.error.text) {
                const endpoint = `${userAuthConstants.API.LOGIN_URL}`;
                userAuthSrvc.send(endpoint,
                    {
                        email: $scope.email.value,
                        password: $scope.password.value
                    }).then(
                    function (response) {
                        $scope.error.text = userAuthConstants.ERRORS.LOGIN_SUCCESS;
                        $scope.error.type = 'success';
                        userAuthSrvc.writeJwtToCookie(response.data.token);
                        environmentSrvc.getVariable('TERMINUS_HUB_URL').then(url => {
                            userAuthSrvc.redirect(url);
                        });
                    },
                    function (error) {
                        $scope.error.type = 'error';
                        $scope.button.enabled = true;
                        switch (error.status) {
                        case 504:
                            $scope.error.text = userAuthConstants.ERRORS.DEADLINE_EXCEEDED;
                            break;
                        case 500:
                            $scope.error.text = userAuthConstants.ERRORS.UNKNOWN;
                            break;
                        case 403:
                            $scope.error.text = userAuthConstants.ERRORS.PERMISSION_DENIED;
                            break;
                        case 401:
                            $scope.error.text = userAuthConstants.ERRORS.UNAUTHENTICATED;
                            break;
                        case 400:
                            var errorObj = error.data;
                            $scope.error.text = !errorObj || !errorObj.message ? '' : errorObj.message;
                            break;
                        default:
                            $scope.error.text = error.statusText;
                        }
                    });
            }
        }

        function initialize() {
            $scope.header = {
                title: userAuthConstants.LABELS.LOGIN_PAGE_TITLE
            };
            $scope.error = {
                type: 'error',
                text: ''
            };
            $scope.email = {
                label: userAuthConstants.LABELS.EMAIL,
                type: 'text',
                value: ''
            };
            $scope.password = {
                label: userAuthConstants.LABELS.PASSWORD,
                type: 'password',
                value: ''
            };
            $scope.button = {
                label: userAuthConstants.LABELS.LOGIN_BTN,
                enabled: false,
                click: submit
            };
            $scope.linkForgot = {
                label: userAuthConstants.LABELS.FORGOT_PAGE_TITLE,
                value: userAuthConstants.API.FORGOT_PAGE_URL
            };
            $scope.linkEngage = {
                label: userAuthConstants.LABELS.ENGAGE_GO_TO_LINK,
                value: userAuthConstants.API.ENGAGE_PAGE_URL
            };
            $scope.engageLabel = {
                label: userAuthConstants.LABELS.ENGAGE_GO_TO,
            };
        }

        function activate() {
            // Clean up cookies and localstorage at startup
            userAuthSrvc.unauthenticate();

            userAuthSrvc.hasOktaLogin().then(() => {
                environmentSrvc.getVariable(userAuthConstants.API.UNIFIED_LOGIN_URL_VAR).then((host) => {
                    userAuthSrvc.redirect(host + userAuthConstants.API.UNIFIED_LOGIN_ENDPOINT);
                }, () => {
                    throw 'Missing: ' + userAuthConstants.UNIFIED_LOGIN_URL_VAR;
                });
            }, () => {
                if ($scope.$$phase) {
                    initialize();
                } else {
                    $scope.$apply(initialize());
                }
            });

        }

        /**
         * Check if all fields are filled to enable the button.
         */
        $scope.$watch('[ email.value, password.value ]',
            function (newValue) {
                if ($scope.button) {
                    $scope.button.enabled = newValue.every(function (value) {
                        return !!value;
                    });
                }
            });

        activate();
    }
})();
